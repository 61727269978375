<template>
    <div class="main-content">
        <div v-if="!loading" class="web-stat-controls" style="float: right;">
            <label>Last Updated</label><span style="padding: 0 1em 0 0.5em;">{{ lastUpdated }}</span>
            <!-- <div class="button" @click="refreshWebStats()">Update Stats</div> -->
        </div>
        <h1>Web Stats</h1>
        <div class="iframe-wrapper full-page-item">
            <div v-if="loading" class="empty-content">
                <font-awesome-icon icon="spinner" class="spin" />
            </div>
            <iframe v-else :src="getWebStatsURL()" style="width: 100%; height: 100%; border: 0 none;"/>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
export default {
    name: 'Web Stats',
    data() {
        return {
            loading: false,
            message: '',
            lastUpdated: ''
        }
    },
    created() {
        this.refreshWebStats()
    },
    computed: {
        // mix the getters into computed with object spread operator
        ...mapGetters([
            'getApiHostname'
        ])
    },
	methods: {
        ...mapActions([
            'apiCall' // map `this.apiCall()` to `this.$store.dispatch('apiCall')`
        ]),
        getWebStatsURL() {
            return 'https://' + this.getApiHostname + '/filecache/webstats.html'
        },
        refreshWebStats() {
            if (this.loading) { return }
		    this.message = ''
			this.loading = true
    		this.apiCall({ destination: 'get_web_stats' }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
                    this.lastUpdated = obj.last_updated
                    window.scrollTo(0, 0);
    			} else {
					this.message = obj.message
				}
    		})
        }
    }
}
</script>
